import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable'
import { ScrambleTextPlugin } from 'gsap/ScrambleTextPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import initHoverAnimation from './features/animate-features.js'
import dragStuff from './features/drag-stuff.js'
import numberCounter from './features/numbercounter.js'
import scrambleThis from './features/scramble.js'
import videoModal from './features/video-modal.js'
videoModal()
numberCounter()

gsap.registerPlugin(ScrollTrigger)

scrambleThis()
dragStuff()

document.addEventListener('DOMContentLoaded', () => {
  initHoverAnimation()
})

gsap.registerPlugin(Draggable)

// Select the video element and the hover trigger element
const video = document.getElementById('hero-video')
const hoverElement = document.querySelector('.header_video-message')

// Function to play video when hover starts
hoverElement.addEventListener('mouseenter', () => {
  video.play()
})

// Function to pause video when hover ends
hoverElement.addEventListener('mouseleave', () => {
  gsap.to(video, {
    opacity: 0,
    duration: 0.2,
    onComplete: () => {
      video.pause()
      video.currentTime = 0
      gsap.to(video, {
        opacity: 1,
        duration: 0.2,
        ease: 'power2.out',
      })
    },
  })
})

// Register the ScrambleTextPlugin with GSAP
gsap.registerPlugin(ScrambleTextPlugin)

const videoReelText = document.getElementById('video-reel-text')

// Set initial opacity of the text to 0
gsap.set(videoReelText, { opacity: 0 })
gsap.set('.header_video-message-line', { width: '0rem', opacity: 0 })

// Animation on hover start (mouseenter)
hoverElement.addEventListener('mouseenter', () => {
  gsap.to(videoReelText, {
    duration: 1, // Duration of the animation
    opacity: 1, // Fade in the text
    scrambleText: 'Play Reel', // Scramble the text to the final HTML content
  })
  gsap.to('.header_video-message-line', {
    duration: 1, // Duration of the animation
    width: '3.5rem',
    opacity: 1,
  })
})

// Optional: Animation on hover out (mouseleave)
hoverElement.addEventListener('mouseleave', () => {
  gsap.to(videoReelText, { opacity: 0, duration: 0.5 }) // Fade out when hover ends
  gsap.to('.header_video-message-line', {
    duration: 1, // Duration of the animation
    width: '0rem',
    opacity: 0,
  })
})

// Select the circle element
const circle = document.querySelector('.progress-ring__circle')

// When hover starts
document
  .querySelector('.header_video-message')
  .addEventListener('mouseenter', () => {
    gsap.to(circle, { duration: 0.5, strokeDashoffset: 0, ease: 'power2.out' })
  })

// When hover ends
document
  .querySelector('.header_video-message')
  .addEventListener('mouseleave', () => {
    gsap.to(circle, { duration: 0.5, strokeDashoffset: 282.6 })
  })

gsap.set('.header_line-component', { opacity: 0.9 })
gsap.fromTo(
  '.header_line-animation',
  { y: '-5rem', duration: 3, opacity: 0.9 },
  {
    y: '80rem',
    ease: 'power2.Out',
    opacity: 0.2,
    repeat: -1,
    duration: 3,
    stagger: {
      each: 0.8, // the base delay between each element
      from: 'random', // apply stagger randomly across the elements
    },
  }
)

// Line Animation and Heading Fade in
let lineWrappers = document.querySelectorAll('.heading-wrapper')

lineWrappers.forEach((item) => {
  const line = item.querySelector('.line-divider')
  const lineMarker = item.querySelector('.line-marker')
  gsap.from(item, {
    opacity: 0,
    y: 10,
    duration: 1,
    ease: 'power2.out',
    scrollTrigger: {
      trigger: item, // Use the next item as the trigger
      start: 'top 90%',
      /*       toggleActions: 'play reverse play reverse', */
      scrub: false,
    },
  })
  gsap.from(line, {
    width: 0,
    opacity: 0.5,
    duration: 1,
    ease: 'power1.out',
    scrollTrigger: {
      trigger: item, // Use the next item as the trigger
      start: 'top 80%',
      end: 'bottom 0%',
      /*       toggleActions: 'play reverse play reverse', */
      scrub: false,
    },
    onComplete: () => {
      gsap.to(lineMarker, {
        opacity: 0,
        duration: 0.8,
        ease: 'power2.out',
      })
    },
  })
})

let serviceItems = document.querySelectorAll('.layout4_component')

serviceItems.forEach((item) => {
  /*   gsap.from(item, {
    opacity: 0,
    y: 20,
    duration: 1,
    ease: 'power2.out',
    scrollTrigger: {
      trigger: item,
      start: 'top 60%',
      end: 'bottom 0%',
      scrub: false,
    },
  }) */
  gsap.from(item.querySelector('.layout4_content'), {
    opacity: 0,
    y: 10,
    duration: 1,
    ease: 'power2.out',
    scrollTrigger: {
      trigger: item, // Use the next item as the trigger
      start: 'top 80%',
      /*       toggleActions: 'play reverse play reverse', */
      scrub: false,
    },
  })
})

serviceItems.forEach((component) => {
  // Create the ScrollTrigger animation for the .layout4_image-wrapper
  gsap.fromTo(
    component.querySelector('.layout4_image'),
    { scale: 1.2 },
    {
      scale: 1,
      duration: 1,
      ease: 'none',
      scrollTrigger: {
        trigger: component,
        start: 'top 80%', // Adjust based on when you want the animation to start
        end: 'top 20%', // Adjust based on when you want the animation to end
        scrub: true,
      },
    }
  )
})

// unhide header Image
const headerStart = '65% 50%'

gsap.to('.header75_background3', {
  opacity: 1,
  duration: 0.7,
  ease: 'power1.out',
  scrollTrigger: {
    trigger: '.header75_component', // Use the next item as the trigger
    start: headerStart,
    toggleActions: 'play reverse play reverse',
    scrub: false,
  },
})

gsap.to('.header75_content', {
  opacity: 0,
  duration: 0.4,
  ease: 'power1.out',
  scrollTrigger: {
    trigger: '.header75_component', // Use the next item as the trigger
    start: headerStart,
    toggleActions: 'play reverse play reverse',
    scrub: false,
  },
})

gsap.to('.header_line-component', {
  opacity: 0,
  duration: 0.4,
  ease: 'power1.out',
  scrollTrigger: {
    trigger: '.header75_component', // Use the next item as the trigger
    start: '50% 50%',
    toggleActions: 'play reverse play reverse',
    scrub: false,
  },
})

const layoutContent = document.querySelectorAll('.layout439_content')
const layoutIllustrations = document.querySelectorAll('.grid-list3_item')
gsap.set(layoutIllustrations, { opacity: 0, y: 0, scale: 0.5, rotate: 10 })

layoutContent.forEach((item) => {
  gsap.from(item, {
    opacity: 0,
    y: 40,
    duration: 0.7,
    ease: 'power1.out',
    scrollTrigger: {
      trigger: item, // Use the next item as the trigger
      start: '0% 70%',
      toggleActions: 'play reverse play reverse',
      scrub: false,
    },
  })
  const layoutIllustrations = item.querySelectorAll('.grid-list3_item')
  gsap.to(layoutIllustrations, {
    opacity: 1,
    y: 0,
    scale: 1,
    rotate: 0,
    duration: 0.7,
    stagger: 0.1,
    ease: 'back.out(1.7)',
    scrollTrigger: {
      trigger: item,
      start: '0% 70%',
      toggleActions: 'play reverse play reverse',
      scrub: false,
    },
  })
})

gsap.from('#line1', {
  height: '0rem',
  opacity: 0,
  duration: 0.7,
  scrollTrigger: {
    trigger: '#line-wrapper1',
    start: '0% 70%',
    end: () => '+=' + document.querySelector('#line-wrapper2').offsetHeight, // Dynamically calculate the height
    scrub: 1,
  },
})

gsap.from('#line2', {
  height: '0rem',
  opacity: 0,
  duration: 0.7,
  scrollTrigger: {
    trigger: '#line-wrapper2',
    start: '0% 70%',
    end: () => '+=' + document.querySelector('#line-wrapper2').offsetHeight, // Dynamically calculate the height
    scrub: 1,
  },
})

// Select all sections that contain the .layout397_card-small elements
const layoutSections = document.querySelectorAll('.layout397_row')
// Design/Dev/Automation Section Animatin
layoutSections.forEach((section) => {
  // Create a GSAP animation for each section
  gsap.from(section.querySelectorAll('.layout397_card-small'), {
    y: 20,
    opacity: 0,
    duration: 0.7,
    stagger: 0.2,
    scrollTrigger: {
      trigger: section,
      start: 'top 100%',
    },
  })
})

gsap.fromTo(
  '.animated-line_animation',
  { y: -200, duration: 3, opacity: 0 },
  {
    y: 200,
    opacity: 1,
    repeat: -1,
    duration: 3,
    stagger: 0.2,
  }
)

// Portfolio Component Animation
let portfolioItems = document.querySelectorAll('.portfolio2_item')

portfolioItems.forEach((item) => {
  const portfolioCircle = item.querySelector('.portfolio_progress-ring-circle')
  const image = item.querySelector('.portfolio2_image-link')
  item.addEventListener('mouseenter', () => {
    gsap.to(portfolioCircle, {
      duration: 0.5,
      strokeDashoffset: 0,
      ease: 'power2.out',
    })
  })
  item.addEventListener('mouseleave', () => {
    gsap.to(portfolioCircle, { duration: 0.5, strokeDashoffset: 282.6 })
  })
  gsap.from(image, {
    scale: 0.98,
    scrollTrigger: {
      trigger: item, // Use the next item as the trigger
      start: 'top 90%',
      end: 'bottom 0%',
      scrub: false,
    },
  })
  gsap.from(item, {
    y: 20,
    opacity: 0,
    scrollTrigger: {
      trigger: item, // Use the next item as the trigger
      start: 'top 80%',
      end: 'bottom 0%',
      scrub: false,
    },
  })

  const itemTags = item.querySelectorAll('.portfolio2_tag-item')
  gsap.from(itemTags, {
    opacity: 0,
    delay: 0.5,
    y: 5,
    stagger: 0.2,
    scrollTrigger: {
      trigger: itemTags, // Use the next item as the trigger
      start: 'bottom 100%',
      end: 'bottom 0%',
      scrub: false,
    },
  })
})

window.addEventListener('load', function () {
  try {
    const portfolioComponents = document.querySelectorAll('[hover-me]')

    portfolioComponents.forEach((portfolioComponent) => {
      try {
        const hoverMessage =
          portfolioComponent.querySelector('[hover-me-unhide]')
        const hoverImage = portfolioComponent.querySelector('[hover-me-image]')

        let Tl = gsap.timeline({ paused: true })

        if (hoverMessage) {
          Tl.to(hoverMessage, { opacity: 1, duration: 0.3 })
        }

        if (hoverImage) {
          Tl.from(
            hoverImage,
            { scale: 1.01, duration: 0.5, ease: 'power1.out' },
            0
          ) // Add scale animation for hover image
        }

        portfolioComponent.addEventListener('mouseover', function () {
          Tl.play()
        })

        portfolioComponent.addEventListener('mouseout', function () {
          Tl.reverse()
        })
      } catch (componentError) {
        // Do nothing if an error occurs
      }
    })
  } catch (error) {
    // Do nothing if an error occurs
  }
})
gsap.set('.testimonial18_content', { opacity: 0, y: 20 })
gsap.to('.testimonial18_content', {
  opacity: 1,
  y: 0,
  duration: 0.7,
  stagger: 0.2,
  ease: 'power1.out',
  scrollTrigger: {
    trigger: '.testimonial18_component', // Use the next item as the trigger
    start: 'top 70%',
    /*     toggleActions: 'play reverse play reverse', */
    scrub: false,
  },
})
