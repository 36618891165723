import { gsap } from 'gsap'

export default function numberCounter() {
  window.addEventListener('load', function () {
    try {
      // Select all sections that contain the .stat3_item elements
      const statSections = document.querySelectorAll('.section_me')

      statSections.forEach((section) => {
        // Select all .stat3_item elements with the attribute fs-numbercount-element within the section
        const statItems = section.querySelectorAll(
          '.stat3_item [fs-numbercount-element]'
        )

        // Create a GSAP animation for each stat item with a number counter and stagger
        statItems.forEach((statItem) => {
          const endValue = parseInt(statItem.getAttribute('data-end'), 10) || 0
          const counter = { value: 0 }

          gsap.to(counter, {
            value: endValue,
            duration: 2,
            ease: 'power1.out',
            onUpdate: function () {
              statItem.textContent = Math.floor(counter.value)
            },
            scrollTrigger: {
              trigger: section,
              start: 'top 90%',
              once: true,
            },
          })
        })

        // Select all .stat3_progress-bar elements within the section
        const progressBars = section.querySelectorAll('.stat3_progress-bar')
        const statCard = section.querySelectorAll('.stat3_item')
        // Create a staggered animation for the stat items
        gsap.from(statCard, {
          y: 20,
          opacity: 0,
          ease: 'power1.out',
          delay: 0.5,
          duration: 0.7,
          stagger: 0.2,
          scrollTrigger: {
            trigger: section,
            start: 'top 90%',
          },
        })

        // Create a GSAP animation for the progress bars
        progressBars.forEach((progressBar) => {
          gsap.from(progressBar, {
            width: 0,
            duration: 2,
            ease: 'power1.out',
            scrollTrigger: {
              trigger: section,
              start: 'top 90%',
              once: true,
            },
          })
        })
      })
    } catch (error) {
      // Do nothing if an error occurs
    }
  })
}
