import { gsap } from 'gsap'
import { ScrambleTextPlugin } from 'gsap/ScrambleTextPlugin'

export default function scrambleThis() {
  // Register the ScrambleTextPlugin with GSAP
  const scrambleTrigger = document.querySelectorAll('[scramble-trigger]')
  gsap.registerPlugin(ScrambleTextPlugin)

  scrambleTrigger.forEach((hoverElement) => {
    const scrambleText = hoverElement.querySelector('[scramble-text]')
    const finalText = scrambleText.innerHTML
    // Animation on hover start (mouseenter)
    hoverElement.addEventListener('mouseenter', () => {
      gsap.to(scrambleText, {
        duration: 1, // Duration of the animation
        opacity: 1, // Fade in the text
        scrambleText: finalText, // Scramble the text to the final HTML content
      })
    })
    /*     hoverElement.addEventListener('mouseleave', () => {
      gsap.to(scrambleText, {
        duration: 1, // Duration of the animation
        opacity: 0, // Fade in the text
      })
    }) */
  })
}
