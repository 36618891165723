export default function videoModal() {
  const $ = window.jQuery
  const $modalComponent = $('.modal_component')
  const $openTriggerElement = $('[modal-video]')
  const $videoInModal = $modalComponent.find('video')
  const $closeButton = $modalComponent.find('.modal_close-button')
  const $modalBackground = $modalComponent.find('.modal_background-layer')
  const $modalContainer = $modalComponent.find('.modal_container')
  const $body = $('body')

  // Function to open modal with video
  const openModal = function (videoUrl) {
    if (!videoUrl) {
      console.error('No video URL found')
      return
    }

    // Set initial scale for animation
    $modalContainer.css({
      transform: 'scale(0.8)',
      opacity: '0',
    })

    setVideoSrc(videoUrl) // Set the video URL and play the video

    $modalComponent.show()

    // Trigger animation for modal opening
    window.requestAnimationFrame(() => {
      $modalContainer.css({
        transition: 'transform 1s cubic-bezier(.075, .82, .165, 1)',
        transform: 'scale(1)',
        opacity: '1',
      })
    })
  }

  // Function to close modal and stop video
  const closeModal = function () {
    $videoInModal[0].pause() // Stop the video
    removeVideoSrc() // Optionally remove video src
    $modalComponent.hide()

    // Reset the transform for next open
    $modalContainer.css({
      transform: 'scale(0.8)',
    })
  }

  // Disable body scroll when modal is open
  const bodyNoScroll = function () {
    $body.css({
      top: `-${window.scrollY}px`,
      position: 'fixed',
    })
  }

  // Allow body scroll when modal is closed
  const bodyAllowScroll = function () {
    const scrollY = $body.css('top')
    $body.css({
      position: '',
      top: '',
    })
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }

  // Set video source and play the video
  const setVideoSrc = function (videoUrl) {
    $videoInModal.attr('src', videoUrl)
    $videoInModal[0].currentTime = 0 // Start video from the beginning
    $videoInModal[0].muted = false // Ensure video is unmuted
    $videoInModal[0].play() // Play the video
  }

  // Clear video source when closing modal
  const removeVideoSrc = function () {
    $videoInModal.attr('src', '')
  }

  // Get the video source URL from the clicked element
  const getVideoSrc = function (parentElement) {
    let mp4Url = ''
    $(parentElement)
      .find('video source')
      .each(function () {
        const videoSrc = $(this).attr('src')
        if (videoSrc && videoSrc.endsWith('.mp4')) {
          mp4Url = videoSrc
        }
      })
    return mp4Url || ''
  }

  // Handle opening the modal when the trigger element is clicked
  const videoOpenHandler = function (element) {
    const videoUrl = getVideoSrc($(element.currentTarget))
    openModal(videoUrl)
    bodyNoScroll()
  }

  // Handle closing the modal
  const closeModalHandler = function () {
    closeModal()
    bodyAllowScroll()
  }

  // Event listeners
  $openTriggerElement.on('click', videoOpenHandler) // Use 'on' for better compatibility
  $closeButton.on('click', closeModalHandler)
  $modalBackground.on('click', closeModalHandler)

  // Ensure the modal is closed initially
  closeModal()
}
