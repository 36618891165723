import { gsap } from 'gsap'

export default function initHoverAnimation() {
  const $ = window.jQuery
  const items = document.querySelectorAll('.layout254_item')
  const images = document.querySelectorAll('.layout254_image')
  let highestZIndex = 1 // Track the highest z-index value
  let currentIndex = 0 // Start from the first item
  let currentAnimation = null // Keep track of current animation

  // Initially hide all images except the first one and position them 20px below
  gsap.set(images, { opacity: 0, y: 20, zIndex: 1 }) // Set initial z-index to 1
  gsap.set(images[0], { opacity: 1, y: 0, zIndex: highestZIndex })

  // Function to stop any ongoing line animation
  function stopCurrentAnimation() {
    if (currentAnimation) {
      currentAnimation.kill() // Kill any ongoing animation
      currentAnimation = null // Reset current animation
    }
  }

  // Function to handle animation for the current item and image
  function animateItem(index) {
    const currentItem = items[index]
    const currentImage = images[index]
    const lineDivider = $(currentItem).find('.line-divider')
    const lineMarker = $(lineDivider).find('.line-marker') // Find the .line-marker

    // Stop any ongoing line animation before starting the new one
    stopCurrentAnimation()

    // Increase z-index for the new image
    highestZIndex += 1

    // Hide all other images except the one being shown
    images.forEach((image, imgIndex) => {
      if (imgIndex !== index) {
        gsap.to(image, {
          opacity: 0,
          y: 20,
          duration: 0.5,
          ease: 'power2.out',
        })
      }
    })

    // Move the current image to the top by adjusting the z-index
    gsap.set(currentImage, { zIndex: highestZIndex })

    // Fade in the current image while moving it to its original position
    gsap.to(currentImage, {
      opacity: 1,
      y: 0,
      duration: 0.8,
      ease: 'power2.out',
    })

    // Reset the opacity of the line marker to 1 at the start of the animation
    gsap.set(lineMarker, { opacity: 1 })

    // Animate the line-divider as a loading chart from 0% to 100%
    currentAnimation = gsap.fromTo(
      lineDivider,
      { width: '0%' },
      {
        width: '100%',
        duration: 3, // Duration of the loading animation
        ease: 'linear',
        onComplete: () => {
          // Once the line animation is done, fade out the line-marker
          gsap.to(lineMarker, {
            opacity: 0,
            duration: 0.5, // Fade out duration
            ease: 'power2.out',
          })

          // Move to the next item after the marker fades out
          currentIndex = (currentIndex + 1) % items.length
          animateItem(currentIndex)
        },
      }
    )
  }

  // Click event to start the animation for the clicked item
  items.forEach((item, index) => {
    item.addEventListener('click', () => {
      currentIndex = index // Set the clicked item as the active one
      animateItem(currentIndex) // Start the animation
    })
  })

  // Start with the first item when the page loads
  animateItem(0)

  // Document ready for initial line-divider setup
  $(document).ready(function () {
    // Set the initial width of all elements with class 'line-divider' to 0
    gsap.set('.line-divider', { width: 0 })

    // Set all .line-marker elements to opacity 1 by default
    gsap.set('.line-marker', { opacity: 1 })
  })
}
